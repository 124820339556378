<template>
    <section>
        <vue-headful :title="`FLPN Aviasolutions - ${pageTitle}`"></vue-headful>
        <h1 class="title">{{ pageTitle }}</h1>
      <contenedor>
        <form action="#" autocomplete="off" v-on:submit.prevent="save">
            <div class="columns">
                <div class="column is-3">
                    <label class="label">Name</label>
                    <input type="text"
                           class="input"
                           v-model="waypoint.name"
                           v-validate="'required|min:2'">
                </div>
                <div class="column is-3">
                    <label class="label">Description</label>
                    <input type="text"
                           class="input"
                           v-model="waypoint.description"
                           v-validate="'required|min:4'">
                </div>
                <div class="column is-3">
                    <label class="label">Latitude</label>
                    <div class="field has-addons">
                        <p class="control">
                            <span class="select">
                                <select v-model="waypoint.hdms_latitude.hemisphere">
                                    <option>N</option>
                                    <option>S</option>
                                </select>
                            </span>
                        </p>
                        <p class="control is-expanded">
                            <input type="text"
                                   class="input"
                                   v-mask="'######'"
                                   v-model="waypoint.hdms_latitude.format"
                                   v-validate="'required|min:6|max:6'"
                                   placeholder="DDMMSS">
                        </p>
                    </div>
                </div>
                <div class="column is-3">
                    <label class="label">Longitude</label>
                    <div class="field has-addons">
                        <p class="control">
                            <span class="select">
                                <select v-model="waypoint.hdms_longitude.hemisphere">
                                    <option>E</option>
                                    <option>W</option>
                                </select>
                            </span>
                        </p>
                        <p class="control is-expanded">
                            <input type="text"
                                   class="input"
                                   v-mask="'#######'"
                                   v-model="waypoint.hdms_longitude.format"
                                   v-validate="'required||min:7|max:7'"
                                   placeholder="DDDMMSS">
                        </p>
                    </div>
                </div>
            </div>
            <div class="field is-grouped is-grouped-right">
                <p class="control">
                    <router-link :to="'/waypoints'" class="button is-light">
                        <b-icon pack="fas" icon="long-arrow-alt-left"></b-icon>
                        <span class="has-text-weight-bold is-uppercase">Back to Waypoints</span>
                    </router-link>
                </p>
                <p class="control">
                    <button type="submit" class="button is-primary">
                        <b-icon pack="far" icon="save"></b-icon>
                        <span class="has-text-weight-bold is-uppercase">Save</span>
                    </button>
                </p>
            </div>
        </form>
      </contenedor>
    </section>
</template>
<script>
import WaypointService from '../../services/waypoint.service';
import FailedResponse from '../../helpers/failed-response.helper';
import Waypoint from '../../models/waypoint/waypoint';
import Contenedor from "@/components/Contenedor";

export default {
    name: 'WaypointCreator',
  components: {Contenedor},
  data() {
        return {
            pageTitle: 'Create Waypoint',
            waypointService: new WaypointService(),
            waypoint: new Waypoint ({
                client_id: this.$auth.user().client_id
            }),
        };
    },
    computed: { },
    methods: {
        reset: function() {
            const user = this.$auth.user();
            this.waypoint = new Waypoint({
                client_id: user.client_id
            });
            this.$validator.reset();
        },
        save: function() {
            this.$validator.validate().then(valid => {
                if (valid) {
                    const loadingComponent = this.$buefy.loading.open({container: null})
                    this.waypointService.save(this.waypoint).then(
                        ({data}) => {
                            if (!this.waypoint.exists) {
                                this.reset();
                            } else {
                                this.waypoint.fill(data.waypoint)
                            }
                            loadingComponent.close();
                            this.$buefy.toast.open({
                                message: data.notificacion,
                                type: 'is-success'
                            });
                        },
                        (failed) => {
                            loadingComponent.close();
                            const messages = FailedResponse.getMessage(failed);
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: messages,
                                type: 'is-danger'
                            });
                        }
                    );
                }
            });
        }
    },
    watch: {
        '$route' (to) {
            if (to.path === '/waypoints/create') {
                this.reset();
            }
        }
    },
    mounted() {
        if (this.$route.params.id) {
            const loadingComponent = this.$buefy.loading.open({container: null})
            this.waypointService.findOne(this.$route.params.id).then(
                ({data}) => {
                    this.pageTitle = `Edit Waypoint: ${data.name}`;
                    this.waypoint.fill(data);
                    loadingComponent.close();
                },
                () => {
                    loadingComponent.close();
                    this.$buefy.toast.open({
                        message: 'Waypoint not found.',
                        type: 'is-danger'
                    });
                }
            );
        }
    }
}
</script>