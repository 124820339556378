import Model from '../model'
import HDMSCoord from '../../classes/coordinate/HDMSCoord'

export default class Waypoint extends Model
{
    constructor(attr = null) {
        super();
        this.name = null
        this.description = null
        this._hdms_latitude = new HDMSCoord('N')
        this._hdms_longitude = new HDMSCoord('E')
        this.client_id = null;
        if (attr) {
            this.fill(attr)
        }
    }

    // ---------------------------- SETTERS ---------------------------
    set hdms_latitude(model) {
        this._hdms_latitude = HDMSCoord.create(model);
    }

    set hdms_longitude (model) {
        this._hdms_longitude = HDMSCoord.create(model);
    }
    
    // ---------------------------- GETTERS ---------------------------
    get hdms_latitude() {
        return this._hdms_latitude;
    }

    get hdms_longitude () {
        return this._hdms_longitude
    }

    // ---------------------------- METHODS ---------------------------
    toJSON() {
        return {
            name: this.name,
            description: this.description,
            hdms_latitude: this.hdms_latitude.toString(),
            hdms_longitude: this.hdms_longitude.toString(),
            client_id: this.client_id
        };
    }
}