export default class HDMSCoord {
    constructor(hemisphere, format = null) {
        this.hemisphere = hemisphere
        this.format = format
    }
    // ---------------------------- METHODS ---------------------------
    toString () {
        return `${this.hemisphere}${this.format}`
    }

    static create(objRaw) {
        return new HDMSCoord(
            objRaw.hemisphere,
            objRaw.format
        )
    }
}